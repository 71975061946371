import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from "react";
import { Dropdown } from 'primereact/dropdown';

const data = (columns) => {

    return columns?.map((column, index) => {

        return <Column key={index} field={column?.field} sortable={column?.sortable} header={column?.header} className={column?.classNames} body={column?.tamplate} />;

    })

}

export default function RenderTable({ columns, list, entries_count }) {

    const [selectedEntries, setSelectedEntries] = useState({ name: entries_count || 5 });

    const entries = [];

    // Fetch Entries 
    list?.map((item, index) => entries.push({ name: index + 1, code: index + 1 }));

    return (
        <div className=' relative'>

            <DataTable value={list} paginator={list?.length > +selectedEntries?.name} rows={selectedEntries?.name} dataKey="id"
                emptyMessage={'data not found'}>

                {data(columns)}

            </DataTable>

            {
                list?.length >= (entries_count || 5)
                    ?
                    <div className={`w-[150px] absolute ${selectedEntries.name == list?.length ? "" : "bottom-3"}`}>
                        <Dropdown
                            value={selectedEntries}
                            onChange={(e) => setSelectedEntries(e.value)} options={entries}
                            optionLabel="name"
                            placeholder="Entradas"
                            className="w-full !p-0"
                            emptyMessage="no data found"
                        />
                    </div>
                    :
                    null
            }

        </div>
    )

}
