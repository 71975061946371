import axios from "axios";
import { Swal } from "../../data";

class QRCodes {

    getQRCodes(setQRCodes) {

        return axios.post(`amgevicesa/physicalqr/list`).then(response => setQRCodes(response.data?.qrs));

    }

    createQRCodes(count, navigate) {

        return axios.post(`amgevicesa/physicalqr/createbatch`, { n: count }).then(response => {

            return Swal.success('Correcto', 'Códigos QR físicos creados correctamente.').then(_ => navigate('/qr-code'));

        });

    }

    deleteQRCode(qr, list, setList) {

        return axios.post(`amgevicesa/physicalqr/destroy`, { qr: qr }).then(response => {

            const removeFromList = list.filter(item => item.qr != qr);

            setList(removeFromList);

            return Swal.success('Correcto', 'Código QR físico eliminado correctamente.');

        });

    }

    bulkDelete(data, list, setList) {

        return axios.post(`amgevicesa/physicalqr/bulkdestroy`, { qrs: data }, false).then(response => {

            const removeFromList = list.filter(item => !data.includes(item.qr));

            setList(removeFromList);

            return Swal.success('Correcto', 'Códigos QR físicos eliminados correctamente.');

        });
        
    }

}

export {
    QRCodes
}