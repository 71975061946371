import axios from "axios";

class Booking {

    getBooking(setBooking) {

        return axios.post(`appointment/listall`).then(response => setBooking(response.data?.appointments));

    }
    getBookingByDate(date, setBooking) {

        return axios.post(`appointment/listday`, date).then(response => setBooking(response.data?.appointments));

    }

}


export {
    Booking
}