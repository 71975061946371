import { useContext } from "react";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { AppContext } from "../../context/AppContext";

export default function MainContent({ isRenderLayouts, renderBreadCurmb, children }) {

    const { user } = useContext(AppContext);

    return (

        isRenderLayouts
            ?
            <div className={`bg-[#eef6ff] flex-1 ms-[280px] mt-[70px] overflow-y-auto max-h-[90vh]`} style={{ transition: "0.5s" }}>

                <div className='px-[24px]'>

                    {renderBreadCurmb && user?.loggedIn && user?.confirem_email && <Breadcrumb />}

                    {children}

                </div>

            </div>
            :
            children

    )
}
