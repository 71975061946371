import {  SwalControlar } from "../assets/js/utlis.js";
// const baseUrl = 'https://frontera-api.chaaraapp.es/'
// const baseUrl = 'https://frontera.chaaraapp.es/api/'
const token = JSON.parse(localStorage.getItem('user'))?.sessid;
const Swal = new SwalControlar();


export {
    token,
    Swal,
}