import axios from "axios";
import { Swal } from "../../data";

class BannedClients {

    getBannededUsers(setUsers) {

        return axios.post(`user/listbanned`).then(response => setUsers(response.data?.bannedUsers));

    }

    getPeriod(setPeriod) {

        return axios.post(`settings/get`, { name: "periodBanishment" }).then(response => setPeriod(response.data?.value));

    }

    removeBanned(data, list, setList) {

        return axios.post(`user/amnesty`, data).then(response => {

            const removeFromList = list.filter(item => item.deviceid !== data.deviceId);

            setList(removeFromList);

            return Swal.success('Correcto', "Se levantó correctamente la prohibición para los usuarios seleccionados.");

        });

    }

    bulkBanned(data, list, setList) {

        return axios.post(`user/bulkamnesty`, { deviceIds: data }).then(response => {

            const removeFromList = list.filter(item => !data.includes(item.deviceid));

            setList(removeFromList);

            Swal.success('Correcto', "Has eliminado la prohibición correctamente.");

            return removeFromList;

        });

    }

}

export {
    BannedClients
}