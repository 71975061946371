import axios from "axios";

class Statistics {

    getStatisticsEstimatedWaitingTime(setStatistics) {

        return axios.post(`estimates/getStatisticsToday`).then(response => setStatistics(response.data));

    }
    getEstatisticsToday(setStatistics) {

        const data = { "estimateWaitTime": true, "estimateNHourArriving": true, "estimateNHourExiting": true, "estimateNVehiclesWaiting": true, "calculateNPretickets": true }

        return axios.post(`estimates/getEstimatesGraphicsToday`, data).then(response => setStatistics(response.data?.series));
    }

    getStatisticsArrivalsWaitingGraphicsToday(setStatistics) {

        return axios.post(`estimates/getStatisticsArrivalsWaitingGraphicsToday`).then(response => setStatistics(response.data?.series));

    }

    getEstatisticsDates(setStatistics, data) {

        const options = { "estimateWaitTime": true, "estimateNHourArriving": true, "estimateNHourExiting": true, "estimateNVehiclesWaiting": true, "calculateNPretickets": true }

        return axios.post(`estimates/getEstimatesGraphicsDates`, { ...data, ...options }).then(response => setStatistics(response.data?.series));
    }

    getStatisticsArrivalsWaitingGraphicsDates(setStatistics, data) {

        return axios.post(`estimates/getStatisticsArrivalsWaitingGraphicsDates`, data).then(response => setStatistics(response.data?.series));
    }

    linRegGraphic(setStatistics, regression) {

        return axios.post(`estimates/getStatisticsLinRegGraphic`, { regression }).then(response => setStatistics(response.data));

    }

    linRegGraphicTickets(setStatistics) {

        return axios.post(`estimates/getStatisticsLinRegPreticketsGraphic`).then(response => setStatistics(response.data));

    }
    scatterGraphic(setStatistics) {

        return axios.post(`estimates/getStatisticsScatterGraphic`).then(response => setStatistics(response.data?.points))

    }
    timeSeries(setStatistics) {

        return axios.post(`estimates/getStatisticsTimeSeries`).then(response => setStatistics(response.data?.timeSeries));
    }

}

export {
    Statistics
}