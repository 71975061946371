import axios from "axios";
import { Swal } from "../../data";

class GaneralSettings {

    getSettings(setSettings) {

        return axios.post(`settings/getall`, { token: "fgFDGbfgHEHFEHERh5354534HTegbGEH435GERTHTETRKLOIPEWSDBGJYT6U4" }).then(response => setSettings(response.data?.settings));
    }

    setSettings(data) {

        return axios.post(`settings/setall`, data).then(response => {

            return Swal.success('Correcto', `Cambios en el periodo de tiempo realizados con éxito`);

        });
    }

}

export {
    GaneralSettings
}