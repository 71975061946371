import { faClock, faUser } from '@fortawesome/free-regular-svg-icons';
import { faBan, faChartBar, faGear, faHouse, faQrcode, faTag, } from '@fortawesome/free-solid-svg-icons';


const useDataGetter = () => {

    const linkStyle = `w-full text-[var(--gray)] font-[400] flex items-center justify-between`;

    return { linkStyle }

}

const routesData = [
    {
        name: "Página Principal", children: [
            { name: "Estadísticas de hoy.", icon: faHouse, href: "/", PagePermissions: 4 },
            { name: "Estadisticas otras fechas", icon: faChartBar, href: "/other-statistics", PagePermissions: 4 },
            { name: "Correlaciones", icon: faChartBar, href: "/scatter-graphic", PagePermissions: 4 },
            // { name: "Corr. pre-tickets/t. espera", icon: faChartBar, href: "/tickets", PagePermissions: 4 },
            { name: "Series temporales.", icon: faChartBar, href: "/series-graphic", PagePermissions: 4 },
        ]
    },
    {
        name: "Ajustes", children: [
            { name: "Configuración general", icon: faGear, href: "/ganeral-settings", PagePermissions: 4 },
            { name: "Puntos de reseteo de vehiculos en embolsamiento", icon: faGear, href: "/reset-points", PagePermissions: 4 },
        ]
    },
    {
        name: "Controles", children: [
            // { name: "View Slot Settings ", icon: faClock, href: "/times", PagePermissions: 8 },
            { name: "Categorias de tiempo", icon: faClock, href: "/time-classification", PagePermissions: 8 },
            // { name: "Configuración de citas", icon: faClock, href: "/times/add-time", PagePermissions: 8 },
            // { name: "Cerrar Frontera", icon: faClock, href: "/times/close-time", PagePermissions: 8 },
            // { name: "Offeer", icon: faTag, href: "/offers" },
        ]
    },
    {
        name: "Gestión", children: [
            // { name: "Código QR físico", icon: faQrcode, href: "/qr-code", PagePermissions: 16 },
            { name: "Administrar usuarios", icon: faUser, href: "/users", PagePermissions: 1 },
            // { name: "Usuarios bloqueados", icon: faBan, href: "/banned-clients", PagePermissions: 1 },
            // { name: "Administrar reservas", icon: faTag, href: "/booking", PagePermissions: 4 }
        ]
    },

];

export {
    useDataGetter,
    routesData
}
