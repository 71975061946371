import { useEffect, useState } from "react";
import { Auth } from "../../apis/apis";

const useDataGetter = _ => {

    const [visible, setVisible] = useState(false);

    const authUtailty = new Auth();

    const handleLogout = () => {

        const isAllowedCoockies = window.localStorage.getItem('allow-cookies');

        // Logout
        sessionStorage.clear();

        localStorage.clear();

        if (isAllowedCoockies) localStorage.setItem('allow-cookies', true);

        authUtailty.logout().finally(_ => {

            // Navigate user to login page
            window.location.href = '/login';

        });

    };

    useEffect(() => {

        window.addEventListener('click', _ => setVisible(false));

    }, []);

    const handleDropdownClick = e => {
        e.stopPropagation();
        setVisible(perv => !perv);
    }

    return {
        visible,
        setVisible,
        handleDropdownClick,
        handleLogout
    }

}

export {
    useDataGetter
}