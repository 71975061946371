import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'

export default function TableHeader({
    title,
    url,
    items,
    renderPrintBTN,
    addBtnText,
    hideDeleteBtn,
    renderPlusIcon,
    replacePlusIcon,
    deleteFunctionality,
    hideAddButton,
}) {
    return (
        <div className='flex items-center justify-between mb-[32px] min-h-[50px]'>

            <h1 className='text-[var(--gray)] font-[400] text-[24px]'>{title}</h1>

            <div className='flex items-center'>

                {renderPrintBTN && <button className='text-[var(--dark)] me-[16px] px-[16px] py-[10px] font-medium border border-[var(--dark)]'>Imprimir ({items}) seleccionados</button>}

                {hideDeleteBtn ? null : <button onClick={() => deleteFunctionality && deleteFunctionality()} className='text-[var(--danger)] me-[16px] px-[16px] py-[10px] font-medium border border-[var(--danger)]'>Eliminar ({items}) seleccionados</button>}

                {
                    hideAddButton
                        ?
                        null
                        :
                        <Link to={url} className='text-white me-[16px] px-[16px] py-[10px] font-medium bg-[var(--primary)]'>

                            {renderPlusIcon && <FontAwesomeIcon icon={replacePlusIcon || faPlus} className='me-[10px]' />}

                            <span>{addBtnText}</span>

                        </Link>
                }

            </div>

        </div>
    )
}
