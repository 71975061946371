import Swal from "sweetalert2";

class AuthUtilityManager {

    constructor() {

    }

    debounce(func, wait) {
        let timeout;
        return function () {
            const context = this;
            const args = arguments;
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(context, args), 500);
        };
    }

    getPermissions(userAuth, getIds) {
        const permissions = {
            ['Manage User']: 1,
            ['Use Mobile']: 2,
            ['Use Dashboard']: 4,
            ['Manage Timeslots']: 8,
            ['Manage Physical Qr']: 16,
        };

        const result = [];

        for (const permission in permissions) {
            if (userAuth & permissions[permission]) {

                if (getIds) {

                    result.push(permissions[permission]);

                } else {

                    result.push(permission);

                }
            }
        }

        return result;
    }

    isHasPermissionsToShowRoutes = (user, PagePermissions, userAuthorizations) => {

        const userPermissions = this.getPermissions(userAuthorizations, true);

        if (!user.loggedIn) {

            return -1;

        } else if (!user.confirem_email) {

            return 0;

        } else if (userPermissions.includes(PagePermissions)) {

            return 1;

        }

        return false;

    }

}

const getSelectedOption = (list, optionKey, value) => {
    return list?.filter(item => item[optionKey] == value)[0];
}

class DateManger extends AuthUtilityManager {

    constructor() {

        super();

    }

    dateFormatFunc = (date, reversed) => {

        const date_string = new Date(date);

        const year = date_string.getFullYear();
        const month = (date_string.getMonth() + 1).toString().padStart(2, '0');
        const day = date_string.getDate().toString().padStart(2, '0');

        const dateFormat = `${year}-${month}-${day}`;

        if (reversed) return `${day}-${month}-${year}`;

        return dateFormat

    }

    extractTimeFromDate = (dateString, hideMin) => {
        // Create a new Date object from the provided date string
        const date = new Date(dateString);

        if (date == 'Invalid Date') return dateString;

        // Extract hours, minutes, and seconds
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        // Construct the time string
        if (hideMin) return `${hours}:00:00`;;
        const timeString = `${hours}:${minutes}:00`;

        return timeString;
    }

    // Function to calculate the time difference in minutes
    calculateTimeDifference(targetTime) {
        // Get the current time
        var currentTime = new Date();

        // Parse the target time
        var targetTimeParsed = new Date(targetTime);

        // Calculate the time difference in milliseconds
        var timeDifference = currentTime - targetTimeParsed;

        // Convert the time difference to minutes
        var minutesDifference = Math.floor(timeDifference / (1000 * 60));

        return minutesDifference;
    }


}

class UtilityManager extends DateManger {

    constructor() {

        super();

    }

    setCookie(value, expiry) {
        var expires = new Date();
        expires.setTime(expires.getTime() + (expiry * 24 * 60 * 60 * 1000));
        document.cookie = 'sessid' + '=' + value + ';expires=' + expires.toUTCString();
    }


    handleDeleteUser = (handelDeleteFunction, data, list, setList) => {
        Swal.fire({
            title: '¿Estas seguro',
            text: "que quieres hacer esta acción?",
            icon: 'warning',
            confirmButtonText: 'Sí, elimínalo.',
            cancelButtonText: 'Cancelar',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
        }).then((result) => {
            if (result.isConfirmed) {

                handelDeleteFunction && handelDeleteFunction(data, list, setList);

            }
        });
        return;
    }


}

class SwalControlar {

    constructor() {

    }

    success(title, message, props) {

        return Swal.fire({
            icon: 'success',
            title: title || 'Bien...',
            text: message,
            ...props
        })

    }
    warning(title, message, props) {

        return Swal.fire({
            icon: 'warning',
            title: title || 'Oops...',
            text: message,
            ...props
        })

    }

    rejected(title, message, props) {

        return Swal.fire({
            icon: 'error',
            title: title || 'Oops...',
            text: message,
            ...props
        });

    }
};

export {
    AuthUtilityManager,
    SwalControlar,
    UtilityManager,
    getSelectedOption,
}