import React, { createContext, useEffect, useState } from 'react';
const AppContext = createContext();

const AppProvider = ({ children }) => {

    const [isLoading, setIsLoading] = useState(false);

    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')) || JSON.parse(sessionStorage.getItem('user')) || {});

    useEffect(() => {

        // Update User When Localstorage change
        if (JSON.parse(localStorage.getItem('user'))) {

            setUser(JSON.parse(localStorage.getItem('user')));
        }

    }, [localStorage.getItem('user')]);

    return (
        <AppContext.Provider value={{ isLoading, setIsLoading, user, setUser }}>
            {children}
        </AppContext.Provider>
    );
};

export { AppContext, AppProvider };