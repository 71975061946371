import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import { useDataGetter, routesData } from './data';
import { AppContext } from '../../../context/AppContext';
import { useContext } from 'react';
import { UtilityManager } from '../../../assets/js/utlis';


export default function MnueMainContent() {

    const { linkStyle } = useDataGetter();

    const { user } = useContext(AppContext);

    const appUtailtyManager = new UtilityManager();

    const isPageAllow = (PagePermissions) => appUtailtyManager.isHasPermissionsToShowRoutes(user, PagePermissions, user.authorizations);

    const rendereLists = routesData.map((item, index) => {
        return (

            <li className='mb-5' key={index}>

                <h3 className='text-[var(--primary)] font-[400] mb-2'>{item.name}</h3>

                {item.children.map((child, i) => {

                    return (

                        isPageAllow(child.PagePermissions) === 1
                            ?
                            <NavLink to={child.href} key={i} className={`${linkStyle} block mb-[12px] min-h-[40px] panel-link`}>

                                <div className={`rounded-[6px] flex items-center ms-2`}>

                                    <div className='nav-icon w-[24px] h-[24px] rounded-full flex items-center justify-center'>

                                        <FontAwesomeIcon icon={child.icon} />

                                    </div>

                                    <span className='ms-3'>{child.name}</span>
                                </div>

                            </NavLink>
                            :
                            null

                    )
                })}
            </li>


        )
    })

    return (

        <div className={`main-menu-content w-[280px] top-[70px] fixed pt-[40px] px-[24px] shadow-lg overflow-x-hidden overflow-y-auto bg-white`} style={{ height: 'calc(100vh - 70px)' }}>

            <ul> {rendereLists} </ul>

        </div >
    )
}
