import axios from "axios";
import { Swal } from "../../data";

class Categories {

    getCategories(setCategories) {

        return axios.post(`categories/list`).then(response => setCategories(response.data?.categories))

    }

    getCategoriesRoles(categoryId, setCategories) {

        return axios.post(`categoriesrules/list`, { categoryId }).then(response => setCategories(response.data?.categoryRules))

    }

    getSingleCategory(id, setCategories) {

        return axios.post(`categories/read`, { id }).then(response => {

            setCategories(response.data?.category);

            return response.data?.category;
        })

    }
    getSingleCategoryRole(id, setCategory) {

        return axios.post(`categoriesrules/read`, { id }).then(response => {

            setCategory(response.data?.categoryRule);

            return response.data?.categoryRule;
        })
    }

    addCategory(data) {

        return axios.post(`categories/create`, data).then(response => {

            return Swal.success('Correcto', 'La categoría ha sido añadida exitosamente.');

        })
    }
    addCategoryRole(data) {

        return axios.post(`categoriesrules/create`, data).then(response => {

            return Swal.success('Correcto', 'La categoría ha sido añadida exitosamente.');

        })
    }

    updateCategory(data) {

        return axios.post(`categories/update`, data).then(response => {

            return Swal.success('Correcto', 'La categoría ha sido actualizada exitosamente');

        })
    }

    updateCategoryRole(data) {

        return axios.post(`categoriesrules/update`, data).then(response => {

            return Swal.success('Correcto', 'La categoría ha sido actualizada exitosamente');

        })
    }

    deleteCategory(data, list, setList) {

        return axios.post(`categories/delete`, data).then(response => {

            const removeFromList = list.filter(item => item.id != data?.id);

            setList(removeFromList);

            return Swal.success('Correcto', 'La categoría ha sido eliminada exitosamente.');

        });

    }

    deleteCategoryRole(data, list, setList) {

        return axios.post(`categoriesrules/delete`, data).then(response => {

            const removeFromList = list.filter(item => item.id != data?.id);

            setList(removeFromList);

            return Swal.success('Correcto', 'La categoría ha sido eliminada exitosamente.');

        });

    }

}


export {
    Categories
}