import axios from "axios";
import { Swal } from "../../data";

class Users {

    getUsers(setUsers) {

        return axios.post(`amgevicesauser/list`).then(response => setUsers(response.data?.users));

    }

    getUserInfo(setUser, userId) {

        return axios.post(`amgevicesauser/getprofile`, { id: userId }).then(response => {

            setUser(response.data);

            return response.data;
        });
    }

    updateUser(data) {

        return axios.post(`amgevicesauser/update`, data).then(response => Swal.success('Correcto', 'Se han cambiado los datos correctamente.'));

    }

    addUser(data) {

        return axios.post(`amgevicesauser/insert`, data, false).then(response => {

            Swal.success('Correcto', 'Usuario añadido correctamente.');

            return response.data;

        });

    }

    deleteUser(data, list, setList) {

        return axios.post(`amgevicesauser/delete`, data, false).then(response => {

            if (response.data.result === 'OK') {

                const removeFromList = list.filter(item => item.id != data.id);

                setList(removeFromList);

                return Swal.success('Correcto', 'Usuario eliminado correctamente');

            }

            return response

        });

    }
    bulkDelete(data, list, setList) {

        return axios.post(`amgevicesauser/bulkdelete`, data, false).then(response => {

            if (response.data.result === 'OK') {

                const removeFromList = list.filter(item => !data.ids.includes(item.id));

                setList(removeFromList);

                return Swal.success('Correcto', 'Usuario eliminado correctamente.');

            }

            return Swal.warning('Advertencia', 'Algo salió mal. Por favor, inténtalo de nuevo más tarde');

        });

    }

}

export {
    Users
}