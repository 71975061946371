import { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../context/AppContext'
import { useLocation, useNavigate } from 'react-router-dom';
import { UtilityManager } from '../../assets/js/utlis';

export default function AppNaviagtor() {

    const { user } = useContext(AppContext);

    const [routes] = useState(['/login', '/login/confirem-email', "/forget-password"]);

    const location = useLocation().pathname;

    const appUtailtyManager = new UtilityManager();

    const isPageAllow = (PagePermissions) => appUtailtyManager.isHasPermissionsToShowRoutes(user, PagePermissions, user.authorizations);

    const navigate = useNavigate();

    useEffect(() => {

        if (isPageAllow() === -1) {

            if (location != "/forget-password" && location !== '/rejected-cookies') {

                return navigate('/login');
            }

        } else if (isPageAllow() === 0) {

            if (!routes.includes(location)) {

                return navigate('/login/confirem-email');

            }

        } else if (routes.includes(location)) {

            return navigate('/');

        }

    }, [location]);

    return null;

}
