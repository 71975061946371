import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { Link } from 'react-router-dom';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { UtilityManager } from '../../assets/js/utlis';

export default function TableActions({
    handelDeleteFunction,
    disableEdit,
    renderPrintIcon,
    path,
    data,
    list,
    setList,
    hideDeleteBtn,
    renderEditIcon,
    additionalButton,
    children
}) {

    const appUtailtyManager = new UtilityManager();

    return (

        <div className='flex items-center relative '>

            {additionalButton}

            {hideDeleteBtn ? null : <FontAwesomeIcon onClick={_ => appUtailtyManager.handleDeleteUser(handelDeleteFunction, data, list, setList)} className={`text-[20px] text-[#FF5C34] cursor-pointer`} icon={faTrashCan}></FontAwesomeIcon>}

            {
                renderEditIcon
                    ?
                    <Link to={path} className={`cursor-pointer me-5 ms-10 ${disableEdit ? "hidden" : ""}`}>

                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                            <path d="M17.4091 4.97442L20.0255 7.58958M19.0916 2.67056L12.0169 9.7452C11.6514 10.1102 11.4021 10.5753 11.3005 11.0818L10.647 14.3529L13.9181 13.6982C14.4246 13.5969 14.889 13.3486 15.2547 12.983L22.3293 5.90832C22.5419 5.69572 22.7106 5.44334 22.8256 5.16557C22.9407 4.8878 22.9999 4.59009 22.9999 4.28944C22.9999 3.98879 22.9407 3.69108 22.8256 3.41331C22.7106 3.13554 22.5419 2.88316 22.3293 2.67056C22.1167 2.45797 21.8644 2.28933 21.5866 2.17427C21.3088 2.05922 21.0111 2 20.7105 2C20.4098 2 20.1121 2.05922 19.8343 2.17427C19.5566 2.28933 19.3042 2.45797 19.0916 2.67056Z" stroke="#0071E2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M20.5297 16.8236V20.5295C20.5297 21.1848 20.2694 21.8132 19.8061 22.2765C19.3427 22.7399 18.7143 23.0002 18.0591 23.0002H4.47063C3.81538 23.0002 3.18696 22.7399 2.72363 22.2765C2.2603 21.8132 2 21.1848 2 20.5295V6.94109C2 6.28583 2.2603 5.65742 2.72363 5.19409C3.18696 4.73076 3.81538 4.47046 4.47063 4.47046H8.17657" stroke="#0071E2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                    </Link>
                    :
                    null
            }

            {renderPrintIcon && <FontAwesomeIcon icon={faPrint} className='text-[var(--dark)] text-[24px] cursor-pointer mx-5' />}


            {children}
        </div >
    )
}
