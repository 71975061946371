import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import { UtilityManager } from "../assets/js/utlis";
import { Auth, Users } from "../apis/apis";
import Swal from "sweetalert2";
import { addLocale } from 'primereact/api';

// Update Default Calendar locale Attr
addLocale('es', {
    firstDayOfWeek: 1,
    dayNames: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
    dayNamesShort: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
    dayNamesMin: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
    monthNames: [
        'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio',
        'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'
    ],
    monthNamesShort: [
        'ene', 'feb', 'mar', 'abr', 'may', 'jun',
        'jul', 'ago', 'sep', 'oct', 'nov', 'dic'
    ],
    today: 'Hoy',
});

const useAppControlar = () => {

    const location = useLocation().pathname;

    const [isRenderLayouts, setIsRenderLayouts] = useState(true);

    const [renderBreadCurmb, setRenderBreadCurmb] = useState(true);

    const authUtailty = new Auth();

    const userUtailty = new Users();

    const appUtailtyManager = new UtilityManager();

    const [userInfo, setUserInfo] = useState({});

    const { user, setUser } = useContext(AppContext);

    const isPageAllow = (PagePermissions) => appUtailtyManager.isHasPermissionsToShowRoutes(user, PagePermissions, user.authorizations);

    const arrOfNotAvaliableHeader = ['/login', '/login/confirmation', '/forget-password', '/login/confirem-email', '/rejected-cookies'];

    // #Control Active Time For User
    useEffect(() => {

        let interval;

        // if (user.confirem_email == true) {
        if (user.confirem_email == true) {

            interval = setInterval(() => {

                const sessionTime = new Date(+sessionStorage.getItem('active-time'));

                sessionTime.setMinutes(sessionTime.getMinutes());

                var difference = appUtailtyManager.calculateTimeDifference(sessionTime);

                if (difference >= 1110) {

                    const isAllowedCoockies = window.localStorage.getItem('allow-cookies');

                    // Logout
                    sessionStorage.clear();

                    localStorage.clear();

                    if (isAllowedCoockies) localStorage.setItem('allow-cookies', true);

                    authUtailty.logout().finally(_ => {

                        // Navigate user to login page
                        window.location.href = '/login';

                    });

                }

            }, 1000);

        }

        // clean up
        return () => clearInterval(interval);

    }, [user.confirem_email]);

    useEffect(() => {

        // To Control Toggle Show Header
        arrOfNotAvaliableHeader.includes(location) ? setIsRenderLayouts(false) : setIsRenderLayouts(true);

        // Clean Up
        return () => { };

    }, [location]);

    useEffect(() => {

        setRenderBreadCurmb(true);

        // Clean Up
        return () => { };


    }, [window.location.href]);


    useEffect(() => {

        if (user?.loggedIn && user?.confirem_email && user?.id && isPageAllow(4) === 1) {

            userUtailty.getUserInfo(setUserInfo, user.id).then(data => {

                // Update Localsotrage
                localStorage.setItem('user', JSON.stringify({ ...user, ...data, sessid: user?.sessid, authorizations: data?.authorizations || 0 }));

                setUser({ ...user, authorizations: data?.authorizations });

            });

        } else if (user?.loggedIn && user?.confirem_email && isPageAllow(4) === false) {

            // Navigate To Login Again cuz he doesn't have acsses to dashboard
            Swal.fire({
                title: "Oops",
                text: "No tienes permiso para acceder al panel de control.",
                icon: "error"
            }).then(_ => {

                localStorage.clear();

                window.location.reload();


            })

        }

        // Clean Up
        return () => { };

    }, []);

    return { isRenderLayouts, renderBreadCurmb, setRenderBreadCurmb };

}


export default function useCustomEffect() {
    return { useAppControlar }
}
