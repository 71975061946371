import { Suspense, useContext } from "react";
import useHandleRoutes from "./routes/routes";
import Header from "./layouts/Header";
import MainPanel from "./layouts/MainPanel/MainPanel";
import MainContent from "./layouts/MainContent/MainContent";
import useCustomEffect from "./hooks/useCustomEffect";
import Loader from "./pages/Loader";
import { AppContext } from "./context/AppContext";
import { AppNaviagtor } from "./components";
import { useAxiosConfig } from "./assets/js/useAxiosConfig";

function App() {


  const { useAppControlar } = useCustomEffect();

  const { isRenderLayouts, renderBreadCurmb, setRenderBreadCurmb } = useAppControlar();

  const { routes } = useHandleRoutes(setRenderBreadCurmb);

  const { isLoading, user } = useContext(AppContext);

  const { } = useAxiosConfig();

  return (

    <div className="App">

      <Suspense fallback={<Loader />}>

        <AppNaviagtor />

        {isRenderLayouts && user?.loggedIn && user?.confirem_email && <Header />}

        {isLoading ? <Loader /> : null}

        <div className={`${isRenderLayouts ? "flex" : ""}`}>

          {isRenderLayouts && user?.loggedIn && user?.confirem_email && <MainPanel />}

          <MainContent renderBreadCurmb={renderBreadCurmb} isRenderLayouts={isRenderLayouts}>

            {routes}

          </MainContent>

        </div>

      </Suspense>

    </div>

  );
}

export default App;
