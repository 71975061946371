import axios from "axios";
import { Swal } from "../../data";

class ResetPoints {

    getResetPoints(setResetPoints) {

        return axios.post(`resetPoints/list`).then(response => setResetPoints(response.data?.resetPoints?.map(item => {
            return {...item , id: item?.timePoint};
        })))

    }

    addResetPoint(data) {

        return axios.post(`resetPoints/create`, data).then(response => {

            return Swal.success('Correcto', 'sido añadida exitosamente.');

        })
    }

    updateCategory(data) {

        return axios.post(`categories/update`, data).then(response => {

            return Swal.success('Correcto', 'La categoría ha sido actualizada exitosamente');

        })
    }

    deleteResetPoints(data, list, setList) {

        return axios.post(`resetPoints/delete`, data).then(response => {

            const removeFromList = list.filter(item => item.id != data?.id);

            setList(removeFromList);

            return Swal.success('Correcto', 'La categoría ha sido eliminada exitosamente.');

        });

    }

}


export {
    ResetPoints
}