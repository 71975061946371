import React, { lazy, useContext } from 'react'
import { useRoutes } from 'react-router-dom';
import { AppContext } from '../context/AppContext';
import { UtilityManager } from '../assets/js/utlis';


const Login = lazy(_ => import('../pages/Login/Login'));
const RejectedCookies = lazy(_ => import('../pages/RejectedCookies/RejectedCookies'));
const ForgetPassword = lazy(_ => import('../pages/ForgetPassword/ForgetPassword'));

const ConfiremEmail = lazy(_ => import('../pages/ConfiremEmail'));


const Profile = lazy(_ => import('../pages/Profile/Profile'));
const EditProfile = lazy(_ => import('../pages/EditProfile/EditProfile'));

const Home = lazy(_ => import('../pages/Home/Home'));



const ResetPoints = lazy(_ => import('../pages/ResetPoints/ResetPoints'));
const AddPointsForm = lazy(_ => import('../pages/ResetPoints/components/AddPointsForm'));

const ScatterGraphic = lazy(_ => import('../pages/ScatterGraphic/ScatterGraphic'));
const SeriesGraphic = lazy(_ => import('../pages/SeriesGraphic/SeriesGraphic'));
const GaneralSettings = lazy(_ => import('../pages/GaneralSettings/GaneralSettings'));

const Times = lazy(() => import('../pages/Times/Times'));
const AddTime = lazy(() => import('../pages/Times/components/AddTime/AddTime'));
const CloseTime = lazy(() => import('../pages/Times/components/CloseTime/CloseTime'));
const Report = lazy(() => import('../pages/Times/components/Report/Report'));

const TimeClassification = lazy(() => import('../pages/TimeClassification'));
const AddTimeClassification = lazy(() => import('../pages/TimeClassification/components/AddTime'));
const EditTimeClassification = lazy(() => import('../pages/TimeClassification/components/EditTime'));


const CategoriesRules = lazy(() => import('../pages/CategoriesRoles'));
const AddCategoryRule = lazy(() => import('../pages/CategoriesRoles/components/AddCategoryRole'));
const EditCategoryRole = lazy(() => import('../pages/CategoriesRoles/components/EditCategoryRole'));

const Offer = lazy(() => import('../pages/Offer/Offer'));
const EditOffer = lazy(() => import('../pages/Offer/components/EditOffer/EditOffer'));

const QRCode = lazy(() => import('../pages/QRCode/QRCode'));
const CreateQRCode = lazy(() => import('../pages/QRCode/components/Create/CreateQr'));
const NewQRCode = lazy(() => import('../pages/QRCode/components/NewQrCodes/NewQrCodes'));


const Users = lazy(() => import('../pages/Users/Users'));
const AddUser = lazy(() => import('../pages/Users/components/AddUser/AddUser'));
const EditUser = lazy(() => import('../pages/Users/components/EditUser/EditUser'));

const Tickets = lazy(() => import('../pages/Tickets'));


const BannedClients = lazy(() => import('../pages/BannedClients'));

const Booking = lazy(() => import('../pages/Booking'));

const NotFound = lazy(_ => import('../pages/NotFound/NotFound'));

export default function useHandleRoutes(setRenderBreadCurmb) {

    const { user } = useContext(AppContext);

    const appUtailtyManager = new UtilityManager();

    const isPageAllow = (PagePermissions) => appUtailtyManager.isHasPermissionsToShowRoutes(user, PagePermissions, user.authorizations);

    let routes = useRoutes([
        // AddPointsForm
        { path: "login", element: isPageAllow() === -1 || isPageAllow() === 0 ? <Login /> : <NotFound setRenderBreadCurmb={setRenderBreadCurmb} /> },
        { path: "rejected-cookies", element: isPageAllow() === -1 || isPageAllow() === 0 ? <RejectedCookies /> : null },
        { path: "login/confirem-email", element: isPageAllow() === 0 ? <ConfiremEmail /> : null },
        { path: "forget-password", element: isPageAllow() === -1 || isPageAllow() === 0 ? <ForgetPassword /> : null },
        { path: "/", element: isPageAllow(4) === 1 ? <Home isHasFilter={false} /> : null },
        { path: "/other-statistics", element: isPageAllow(4) === 1 ? <Home isHasFilter={true} /> : null },
        { path: "/scatter-graphic", element: isPageAllow(4) === 1 ? <ScatterGraphic /> : null },
        { path: "/series-graphic", element: isPageAllow(4) === 1 ? <SeriesGraphic /> : null },
        { path: "ganeral-settings", element: isPageAllow(4) === 1 ? <GaneralSettings /> : null },
        { path: "profile", element: isPageAllow(4) === 1 ? <Profile /> : null },
        { path: "reset-points", element: isPageAllow(4) === 1 ? <ResetPoints /> : null },
        { path: "reset-points/add-point", element: isPageAllow(4) === 1 ? <AddPointsForm /> : null },
        { path: "edit-user", element: isPageAllow(4) === 1 ? <EditProfile /> : null },
        // { path: "times", element: isPageAllow(8) === 1 ? <Times /> : null }, 
        { path: "time-classification", element: isPageAllow(8) === 1 ? <TimeClassification /> : null },
        { path: "time-classification/add-time-classification", element: isPageAllow(8) === 1 ? <AddTimeClassification /> : null },
        { path: "time-classification/edit-time-classification", element: isPageAllow(8) === 1 ? <EditTimeClassification /> : null },

        { path: "time-classification/rules", element: isPageAllow(8) === 1 ? <CategoriesRules /> : null },
        { path: "time-classification/rules/add-rule", element: isPageAllow(8) === 1 ? <AddCategoryRule /> : null },
        { path: "time-classification/rules/edit-rule", element: isPageAllow(8) === 1 ? <EditCategoryRole /> : null },


        // { path: "tickets", element: isPageAllow(1) === 1 ? <Tickets /> : null },


        // { path: "times/add-time", element: isPageAllow(8) === 1 ? <AddTime /> : null },
        // { path: "times/close-time", element: isPageAllow(8) === 1 ? <CloseTime /> : null },
        // { path: "times/add-time/report", element: isPageAllow(8) === 1 ? <Report /> : null },
        // { path: "times/close-time/report", element: isPageAllow(8) === 1 ? <Report /> : null },
        // { path: "offers", element: isPageAllow(4) === 1 ? <Offer /> : null },
        // { path: "offers/edit-offer", element: isPageAllow(4) === 1 ? <EditOffer /> : null },
        // { path: "qr-code", element: isPageAllow(16) === 1 ? <QRCode /> : null },
        // { path: "qr-code/create-qr", element: isPageAllow(16) === 1 ? <CreateQRCode /> : null },
        // { path: "qr-code/create-qr/new-codes", element: isPageAllow(16) === 1 ? <NewQRCode /> : null },
        { path: "users", element: isPageAllow(1) === 1 ? <Users /> : null },
        { path: "users/add-user", element: isPageAllow(1) === 1 ? <AddUser /> : null },
        { path: "users/edit-user", element: isPageAllow(1) === 1 ? <EditUser /> : null },
        // { path: "banned-clients", element: isPageAllow(1) === 1 ? <BannedClients /> : null },
        // { path: "booking", element: isPageAllow(4) === 1 ? <Booking /> : null },
        { path: "users", element: isPageAllow(1) === 1 ? <Users /> : null },

    ]);

    return { routes }
}
