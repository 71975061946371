import axios from "axios";
import { Swal } from "../../apis/data";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";


const useAxiosConfig = () => {

    const { user } = useContext(AppContext);

    axios.defaults.baseURL = 'https://frontera-api.chaaraapp.es/';
    axios.defaults.headers.common['Authorization'] = `${JSON.parse(localStorage.getItem('user'))?.sessid}`;
    axios.defaults.headers.common['sessid'] = `${JSON.parse(localStorage.getItem('user'))?.sessid}`;
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    axios.defaults.headers.common['Accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';

    // Add a request interceptor
    axios.interceptors.request.use(
        (config) => {
            // Set loading to true when the request starts
            sessionStorage.setItem('active-time', new Date().getTime());
            return config;
        },
        (error) => {
            // Handle request errors here
            sessionStorage.setItem('active-time', new Date().getTime());
            return Promise.reject(error);
        }
    );

    // Add a response interceptor
    axios.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            // Handle response errors here
            if (error.response) {

                if (error.response.status === 400 && user?.id) {
                    Swal.warning('Advertencia', 'Su sesión ha expirado. Por favor Haga login otra vez')
                        .then(_ => localStorage.clear())
                        .then(_ => window.location.reload())

                }
                Promise.reject(error).catch(
                    error => Swal.warning('Advertencia', 'Algo salió mal. Por favor, inténtalo de nuevo más tarde')
                )

            }

            return Promise.reject(error);
        }
    );

    return {};
};


export {
    useAxiosConfig
}