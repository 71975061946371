import axios from "axios";
import { Swal } from "../../data";

class Times {

    getTimes(setTimes, date) {

        return axios.post(`slots/getsettingsfordate`, { date }).then(response => setTimes(response.data?.settings));

    }
    getCloseTimes(setTimes) {

        return axios.post(`slots/listfrontierclosedtimes`).then(response => setTimes(response.data?.list));

    }
    preStartTime(data, listOfCloseTimes, setListOfCloseTimes) {

        return axios.post(`slots/deletefrontierclosed`, data).then(response => {

            const removeItemFromTheList = listOfCloseTimes.filter(item => item?.timeCreated !== data?.timeCreated);

            Swal.success('Correcto', 'Reapertura de frontera exitosa.');

            return setListOfCloseTimes(removeItemFromTheList);

        });

    }
    addTime(date, navigate) {

        return axios.post(`slots/setforperiod`, date).then(response => {

            sessionStorage.setItem('report', true);

            return navigate('report')

        });
    }
    reportPerview(setPerview) {

        return axios.post(`slots/reportchangespreview`).then(response => setPerview(response.data?.preview));

    }
    closeTime(date, navigate) {

        return axios.post(`slots/setforperiod`, date).then(response => {

            sessionStorage.setItem('report', true);

            return navigate('report');

        });

    }

    getReport(state) {

        return axios.post(`slots/reportresultsuggestedchanges`).then(response => {

            return state(response.data);

        });

    }

    confiremReport(navigate) {

        return axios.post(`slots/confirmchanges`).then(response => {

            sessionStorage.removeItem('report');

            return Swal.success('Correcto', 'Confirmado correctamente').then(_ => navigate('/'));

        });

    }

    discardReport(navigate) {

        return axios.post(`slots/discardchanges`).then(response => {

            sessionStorage.removeItem('report');

            return Swal.success('Correcto', 'Descartado correctamente.').then(_ => navigate('/times/add-time'))

        });
    }

}

export {
    Times
}