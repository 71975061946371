import axios from "axios";
import { UtilityManager } from "../../../assets/js/utlis";
import { Swal } from "../../data";

const appUtailtyManager = new UtilityManager();

class Auth {

    login(data, setUser, navigate) {

        return axios.post(`amgevicesauser/login`, { ...data, 'loginFrom': "web" }, false).then(response => {

            if (response.data.result === "OK") {

                setUser({ loggedIn: true, confirem_email: false, dni: data.dni });

                // Append It to sessions storage cuz maybe user refrech the page in the worest scenario
                sessionStorage.setItem('user', JSON.stringify({ loggedIn: true, confirem_email: false, dni: data.dni }));

                // Navigate user to Confirmeation Page
                return setTimeout(() => {

                    navigate('/login/confirem-email');

                }, 100);

            } else {

                return Swal.rejected(null, `Los datos proporcionados son inválidos.`);

            }

        });
    }

    logout() {

        return axios.post(`amgevicesauser/logout`).then(response => {

            // For Logging Out

        });
    }

    isEmailExsist(email) {

        return axios.post(`amgevicesauser/emailexists`, email, false).then(response => response.data?.exists);

    }

    isDniExsist(dni) {

        return axios.post(`amgevicesauser/dniexists`, dni, false).then(response => response.data?.exists)

    }

    confiremEmail(data, setUser, navigate) {

        return axios.post(`amgevicesauser/mfaconfirmlogin`, data, false).then(response => {

            if (!response.data.result) {

                const user = { loggedIn: true, confirem_email: true, dni: data.dni };

                const isPageAllow = (PagePermissions) => appUtailtyManager.isHasPermissionsToShowRoutes({ loggedIn: true, confirem_email: true }, PagePermissions, response.data.authorizations, true);

                if (isPageAllow(4) !== false) {

                    setUser(user);

                    // Append It to sessions storage cuz maybe user refrech the page in the worest scenario 
                    sessionStorage.clear();

                    // #Set Active Session Time
                    sessionStorage.setItem('active-time', new Date().getTime());

                    localStorage.setItem('user', JSON.stringify({ ...response.data, loggedIn: true, confirem_email: true, dni: data.dni }));

                    appUtailtyManager.setCookie(response.data.sessid, 2);

                    // Navigate user to Confirmeation Page
                    return navigate('/');

                } else {

                    Swal.rejected(null, `No tienes acceso para abrir el panel de control.`);

                    return navigate('/login');

                }


            } else {

                return Swal.rejected(null, `Los datos proporcionados son inválidos.`);

            }


        })
    }

    forgetPassword(data) {

        return axios.post(`amgevicesauser/resetpasswdsendmfa`, data, false)
            .then(response => response.data)
            .catch(_ => Swal.warning('Advertencia', 'Nombre de usuario incorrecto.'))

    }

    changePassword(data, naviage) {

        return axios.post(`amgevicesauser/passwdresetajax`, data, false).then(response => {

            return Swal.success('Correcto', 'Contraseña actualizada correctamente.').then(_ => naviage('/login'));

        })
    }

}

export {
    Auth
}