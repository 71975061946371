import { faBell } from '@fortawesome/free-regular-svg-icons'
import { faAngleDown, faRightFromBracket, } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom';
import { useDataGetter } from './data';

export default function Header() {

    const {
        visible,
        setVisible,
        handleDropdownClick,
        handleLogout
    } = useDataGetter();

    return (

        <header className='flex items-center justify-between fixed z-[1000] w-full h-[70px] bg-[var(--primary)] px-[24px]'>

            <div className='flex items-center'>

                <div className='w-[240px]'>

                    <img src={require('../../assets/images/text-logo.png')} alt='' loading='lazy' className='max-w-full' />

                </div>

            </div>

            <div className="flex items-center gap-10 bg-white px-5 h-full">

                <img
                    src={require('./images/ngeu_es.png')}
                    alt=''
                    loading='lazy'
                    className='max-w-full w-[120px] object-cover max-h-full'
                />

                <img
                    src={require('./images/ciudad-autonoma-ceuta.png')}
                    alt=''
                    loading='lazy'
                    className='max-w-full w-[120px] object-cover max-h-full'
                />

                <img
                    src={require('./images/resiliencia.png')}
                    alt=''
                    loading='lazy'
                    className='max-w-full w-[120px] object-cover max-h-full'
                />


            </div>

            <div className='flex items-center'>

                <FontAwesomeIcon icon={faBell} className='text-white text-[20px] me-[24px]' />

                <div onClick={handleDropdownClick} className='flex items-center cursor-pointer relative'>

                    <img src={require('../../assets/images/default-user.png')} className='rounded-full w-[40px] h-[40px] border border-white me-2' />

                    <h5 className='text-[14px] font-medium text-white'>{JSON.parse(localStorage.getItem('user'))?.name}</h5>

                    <FontAwesomeIcon icon={faAngleDown} className='text-white ms-[12px]' />

                    <div className={`absolute min-w-[150px] w-full shadow-lg z-[100] bg-white border border-t-[transparent] transition left-0 ${visible ? "bottom-[-95px] visible opacity-100" : "bottom-[-150px] opacity-0 invisible"}`}>

                        <Link to={'/profile'} className='flex items-center  p-2 pb-3 border-b-2'>Perfil</Link>

                        <button onClick={handleLogout} className='flex items-center p-2 text-[#FF5C34] font-medium'>

                            <FontAwesomeIcon icon={faRightFromBracket} className='me-3' />

                            <p className='whitespace-nowrap'>Cerrar sesión</p>

                        </button>

                    </div>

                </div>

            </div>

        </header>
    )
}
