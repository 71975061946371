import axios from "axios";
import { Swal } from "../../data";

class Profile {

    UpdateProfile(data) {

        return axios.post(`amgevicesauser/update`, { email: data.email, name: data.name, surnames: data.surnames }, false).then(response => {

            return Swal.success('Correcto', `Se han cambiado los datos correctamente.`);

        });
    }

}

export {
    Profile
}